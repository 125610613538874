import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button, TextField, Typography, Paper, Box, List, ListItem, Alert } from '@mui/material';
import styled from '@emotion/styled';
import moment from 'moment';

const CenterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90vh',
});

const StyledPaper = styled(Paper)({
  width: '80vw',
  height: '60vh',
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
});

const Timer = styled(Typography)({
  fontSize: '1.5rem',
  color: 'red',
  marginTop: 24
});

const App = () => {
  const [email, setEmail] = useState('');
  const [emailSet, setEmailSet] = useState(false);
  const [title, setTitle] = useState('');
  const [list, setList] = useState([]);
  const [time, setTime] = useState(60);
  const [started, setStarted] = useState(false);
  const [alert, setAlert] = useState(null);
  const titleInputRef = useRef(null);
  const listInputRef = useRef([]);
  const [confirmedItems, setConfirmedItems] = useState([]);
  const location = useLocation();

  useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const emailParam = searchParams.get('email');
      if (emailParam !== null) {
          setEmail(emailParam);
          setEmailSet(true);
      }
      const titleParam = searchParams.get('title');
      if (titleParam !== null) {
          setTitle(titleParam);
      }
  }, [location.search]);

  useEffect(() => {
    if (emailSet) {
      titleInputRef.current.focus();
    }
  }, [emailSet]);

  useEffect(() => {
    if (listInputRef.current[list.length - 1]) {
      setTimeout(() => listInputRef.current[list.length - 1].focus(), 0);
    }
  }, [list]);

  const sendEmail = useCallback(async () => {
    // Replace with your actual API endpoint
    const response = await axios.post('https://memo-api-v1.onrender.com/send_email', {
      email,
      title,
      list,
      date: moment().format('YYYY/MM/DD'),
    });

    if (response.status === 200) {
      setAlert({ type: 'success', message: 'メモ書きをメールで送付しました。' });
    } else {
      setAlert({ type: 'error', message: '何らかの理由でメモ書き内容のメールが送信できませんでした。' });
    }
  },[email, list, title])

  const handleEmailSet = () => {
    if (email.length>0) {
      setEmailSet(true);
    } else {
      window.alert("メールアドレスを入力してから確定ボタンを押します。")
    }
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === 'Enter' && e.keyCode !== 229 && title) { // TODO: 確定処理を除外
      setStarted(true);
      setList(['']);
      if (list.length > 0) listInputRef.current[0].focus();
    }
  };

  const handleListKeyDown = (e, i) => {
    if (e.key === 'Enter' && e.keyCode !== 229 && list[i]) {
      setList([...list.slice(0, i + 1), '', ...list.slice(i + 1)]);
      setConfirmedItems(confirmedItems.concat(i));
    }
  };

  const handleListChange = (e, i) => {
    let newList = [...list];
    newList[i] = e.target.value;
    setList(newList);
  };


  const handleAlertClose = () =>  {
    setAlert(null);
    // setEmail('');
    // setEmailSet(false);
    setTitle('');
    setConfirmedItems([])
    setList([]);
    setTime(60);
    setStarted(false);
  };

  useEffect(() => {
    if (started && time > 0) {
      const timer = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (time === 0) {
      sendEmail();
    }
  }, [time, started, sendEmail]);

  return (
    <CenterBox>
      <Typography variant="h4" style={{marginTop: 32}}>ゼロ秒メモアプリ</Typography>
      {!emailSet ? (
        <>
          <TextField
            label="メールアドレス"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginTop: '2rem', width: "320px"}}
            />
          <p>
            メールアドレスを入力するとメモ書きが始められます。６０秒経過後に自動でメモ書きした内容がメールで送信されます。
          </p>
          <Button variant="contained" color="primary" onClick={handleEmailSet} style={{ marginTop: '1rem' }}>
            確定
          </Button>
        </>
      ) : (
        <>
          <p>ver.1.2.51</p>
          <br />
          <p>{email}</p>
          <Timer>あと {time} 秒</Timer>
          <StyledPaper>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="h6">
                {moment().format('YYYY/MM/DD')}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: -24 }}>
              <TextField
                inputRef={titleInputRef}
                label="タイトル"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onKeyDown={handleTitleKeyDown}
                style={{ flex: '1', fontSize: '2rem', marginTop: '2rem' }}
                disabled={list.length>0}
              />
            </div>
            <List>
              {list.map((item, i) => (
                <ListItem key={i}>
                  {/* 確定したリストアイテムのインデックスに現在のインデックスが含まれている場合、箇条書きで表示します */}
                  {confirmedItems.includes(i) ? (
                    <Typography>{`◉ ${item}`}</Typography>
                  ) : (
                    <TextField
                      value={item}
                      style={{flex:1}}
                      onChange={(e) => handleListChange(e, i)}
                      onKeyDown={(e) => handleListKeyDown(e, i)}
                      inputRef={(el) => listInputRef.current[i] = el}
                      disabled={time===0}
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </StyledPaper>
        </>
      )}
      {alert && (
        <>
        <Alert severity={alert.type} onClose={handleAlertClose} style={{marginTop:32}}>
          {alert.message}
        </Alert>
        <p> x を押すと新しいメモ書きを始めます</p>
        </>
      )}
    </CenterBox>
  );
};

export default App;
